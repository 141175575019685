import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import axios from "axios";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
} from "reactstrap";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import Header from "../../../layout/Header/Header";
import Baja from "../../Baja";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";
import CurrencyInput from "react-currency-input-field";

function ListadoRentas() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_RENTAS = process.env.REACT_APP_URL_RENTAS;
  const URL_CARGOS = process.env.REACT_APP_URL_CARGOS;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_UPMEDIA = process.env.REACT_APP_URL_UPMEDIA;
  const URL_DELMEDIA = process.env.REACT_APP_URL_DELMEDIA;
  
  let hoy = new Date()
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 

  const [renta, setRentas] = useState([]);
  const [frecuencia, setFrecuencia] = useState("");
  const [selectedFrecuencia, setSelectedFrecuencia] = useState("");
  const [selectedActivo, setSelectedActivo] = useState("");
  const [fecha, setFecha] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [observaciones, setObservaciones] = useState("");
  const [colaboradores, setColaboradores] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [modalGenera, setModalGenera] = useState(false);
  const toggleGenera = () => setModalGenera(!modalGenera);
  const [text, setText] = useState(false);
  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");
  // const [activo, setActivo] = useState("");

  // const [modal, setModal] = useState(false);
  // const toggle = () => setModal(!modal);
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);
  const [modalAumento, setModalAumento] = useState(false);
  const toggleAumento = () => setModalAumento(!modalAumento);
  const [idInfo, setIdInfo] = useState("");
  const [idCargo, setidCargo] = useState();
  const [nuevo_importe, setNuevoImporte] = useState();
  const [fecha_inicio, setFechaInicio] = useState();
  const [fecha_fin, setFechaFin] = useState();
  const [fecha_aumento, setFechaAumento] = useState();
  const [ultimo_aumento, setUltimoAumento] = useState();
  const [proximoCargo, setProximoCargo] = useState();
  const [frecuenciaEdit, setFrecuenciaEdit] = useState();
  const [descripcionEdit, setDescripcionEdit] = useState();
  const [observacionesEdit, setObservacionesEdit] = useState();
  const [importe_anterior, setImporteAnterior] = useState();
  const [importe_actual, setImporteActual] = useState();
  const [importeKash, setImporteKash] = useState(0);
  const [importeTar, setImporteTar] = useState(0);
  const [selectedCliente, setSelectedCliente] = useState();
  const [selectedClienteName, setSelectedClienteName] = useState();

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [file, setFile] = useState();
  const [photo, setPhoto] = useState([]);
  const [idContrato, setIdContrato] = useState("");
  const [modalContrato, setModalContrato] = useState(false);
  const toggleContrato = () => setModalContrato(!modalContrato);
  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_RENTAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allRentas = response.data;
             // Array para pagination
             let arrayTabla = allRentas
             .sort((a, b) => (a.clientes[0].nombre_comercial > b.clientes[0].nombre_comercial ? 1 : -1))
             .map((a) => {
                 return {
                   _id: a._id,
                   is_active: a.is_active,
                   ultimo_aumento: a.ultimo_aumento,
                   importe_actual: a.importe_actual,
                   importeKash: a.importeKash,
                   importeTar: a.importeTar,
                   importe_anterior: a.importe_anterior,
                   frecuencia: a.frecuencia,
                   descripcion: a.descripcion,
                   observaciones: a.observaciones,
                   fecha_inicio: a.fecha_inicio,
                   fecha_fin: a.fecha_fin,
                   idCliente: a.clientes[0]._id,
                   cliente: a.clientes[0].nombre_comercial,
                   idPropiedad: a.propiedades[0]._id,
                   propiedad: a.propiedades[0].nombre,
                   proximoCargo: a.proximoCargo,
                   moneda: a.moneda,
                   tipo: a.tipo
                 }
             })
             .filter(function (el) {
               return el != null;
             });
   
           let dataFinal = Object.values(arrayTabla);
   
           setComments(dataFinal);
           //
      })
      .catch((err) => {
        console.log(err);
      });
    axios
      .get(URL_CLIENTES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allClientes = res.data;
        setClientes(allClientes);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // PDF
  let totalImporte = 0;

  let tablaImporte = 0;
  let cantidad = 0;

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFrecuencia == 0 || selectedFrecuencia == a.frecuencia) &&
        (selectedActivo == 0 || selectedActivo == a.is_active)
      ) {
        const importe =
          "$" + new Intl.NumberFormat("en-US").format(a.importe_actual);
        totalImporte = totalImporte + a.importe_actual;
        return [
          a.cliente,
          a.fecha_inicio,
          a.frecuencia,
          importe,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text(`Listado Rentas`, 15, 25);
    doc.autoTable({
      head: [
        [
          "Cliente",
          "Fecha Inicio",
          "Frecuencia",
          "Importe",
        ],
      ],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "Totales",
          "$" + new Intl.NumberFormat("en-US").format(totalImporte),
        ],
      ],
      showFoot: "lastPage",
    });
    doc.save(`Rentas.pdf`);
  }


  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFrecuencia == 0 || selectedFrecuencia == a.frecuencia) &&
        (selectedActivo == 0 || selectedActivo == a.is_active)
      ) {
        return {
          _id: a._id,
          is_active: a.is_active,
          ultimo_aumento: a.ultimo_aumento,
          importe_actual: a.importe_actual,
          importeKash: a.importeKash,
          importeTar: a.importeTar,
          importe_anterior: a.importe_anterior,
          frecuencia: a.frecuencia,
          descripcion: a.descripcion,
          observaciones: a.observaciones,
          fecha_inicio: a.fecha_inicio,
          fecha_fin: a.fecha_fin,
          idCliente: a.idCliente,
          cliente: a.cliente,
          idPropiedad: a.idPropiedad,
          propiedad: a.propiedad,
          proximoCargo: a.proximoCargo,
          moneda: a.moneda,
          tipo: a.tipo
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoRentas";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoRentas",
        sheetFilter: [
          "_id",
          "is_active",
          "ultimo_aumento",
          "importe_actual",
          "importeKash",
          "importeTar",
          "importe_anterior",
          "frecuencia",
          "descripcion",
          "fecha_inicio",
          "fecha_fin",
          "idCliente",
          "cliente",
          "idPropiedad",
          "propiedad",
          "proximoCargo",
          "moneda",
          "tipo",
        ],
        sheetHeader: [
          "_id",
          "is_active",
          "ultimo_aumento",
          "importe_actual",
          "importeKash",
          "importeTar",
          "importe_anterior",
          "frecuencia",
          "descripcion",
          "fecha_inicio",
          "fecha_fin",
          "idCliente",
          "cliente",
          "idPropiedad",
          "propiedad",
          "proximoCargo",
          "moneda",
          "tipo",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = renta.map((a) => {
      if (
        (selectedFrecuencia == 0 || selectedFrecuencia == a.frecuencia) &&
        (selectedActivo == 0 || selectedActivo == a.is_active)
      ) {
        const importe =
          "$" + new Intl.NumberFormat("en-US").format(a.importe_actual);
        totalImporte = totalImporte + a.importe_actual;
        return [
          a.cliente,
          a.fecha_inicio,
          a.frecuencia,
          importe,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF();
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 150, 15, 40, 15);
    doc.text(`Listado Rentas`, 20, 25);
    doc.autoTable({
      head: [
        [
          "Cliente",
          "Fecha Inicio",
          "Frecuencia",
          "Importe",
        ],
      ],
      body: dataPDFLimpia,
      startY: 30,
      foot: [
        [
          "",
          "",
          "Totales",
          "$" + new Intl.NumberFormat("en-US").format(totalImporte),
        ],
      ],
      showFoot: "lastPage",
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Resumen Rentas",
          email: mailTo,
          fileName: "ResumenRentas.pdf",
          att: base64File,
          text: `<!DOCTYPE html>
          <html>
            <head>
              <style>                                
                .img-container {
                                display: block;
                                margin-left: auto;
                                margin-right: auto;
                                height: 90px;
                                width: auto;
                              }
              </style>
            </head>
            <body>
              <img class="img-container" alt="Logo" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" />              
              <h1>${process.env.REACT_APP_NOMBRE_INSTITUCION}</h1>
              <h3>Hola,</h3>
              <h3>Adjunto encontraras el detalle de las renta.</h3>
              <h4>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</h4>
              Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> 
            </body>
          </html>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }

  function Info(id) {
    setIdInfo(id);
    toggle();
  }

  function jalaInfoEdit(idEdit, idCliente, cliente, fecha_inicio, fecha_fin, descripcion, observaciones) {

        setSelectedCliente(idCliente);
        setSelectedClienteName(cliente);
        setFechaInicio(fecha_inicio);
        setFechaFin(fecha_fin);
        setDescripcionEdit(descripcion);
        setObservacionesEdit(observaciones);
        setidCargo(idEdit);
        toggleEdit();

  }

  function editCargoMensual(event) {
    event.preventDefault();
    const URL_RENTAS_EDIT = `${process.env.REACT_APP_URL_RENTAS}/${idCargo}`;
    axios
      .patch(
        URL_RENTAS_EDIT,
        {
          fecha_inicio,
          descripcion: descripcionEdit,
          observaciones: observacionesEdit,
          clientes: selectedCliente,
          importeKash,
          importeTar,
          fecha_fin
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Renta",
            detalle: `${selectedCliente}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        console.log(error);
      });
  }

  function jalaInfoAumento(
    idEdit,
    cliente,
    fecha_inicio,
    importe_anterior,
    importe_actual,
    importeKash,
    importeTar,
    ultimo_aumento,
    frecuencia,
    proximoCargo
  ) {
    setSelectedClienteName(cliente);
    setFechaInicio(fecha_inicio);
    setidCargo(idEdit);
    setImporteAnterior(importe_anterior);
    setImporteActual(importe_actual);
    setImporteKash(importeKash);
    setImporteTar(importeTar);
    setNuevoImporte(importe_actual);
    setUltimoAumento(ultimo_aumento);
    setFrecuenciaEdit(frecuencia);
    setProximoCargo(proximoCargo);
    setFechaAumento(endDate);
    toggleAumento();
  }

  function aumentoCargoMensual(event) {
    event.preventDefault();
    axios
      .patch(
        `${URL_RENTAS}/${idCargo}`,
        {
          frecuencia: frecuenciaEdit,
          importe_actual: nuevo_importe,
          importe_anterior: importe_actual,
          importeKash,
          importeTar,
          ultimo_aumento: fecha_aumento,
          proximoCargo
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        Swal.fire("Good job!", "Actualizado con exito", "success");
        
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Propiedad", field: "propiedad", sortable: true },
    { name: "Cliente", field: "cliente", sortable: true },
    { name: "Fecha Inicio", field: "fecha_inicio", sortable: true },
    { name: "Fecha Fin", field: "fecha_fin", sortable: true },
    { name: "Ultimo Aumento", field: "ultimo_aumento", sortable: true },
    { name: "Frecuencia", field: "frecuencia", sortable: true },
    { name: "Importe", field: "importe_actual", sortable: true },
    // { name: "Tipo", field: "tipo", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.propiedad.toLowerCase().includes(search.toLowerCase()) ||
          comment.cliente.toLowerCase().includes(search.toLowerCase()) ||
          comment.frecuencia.toLowerCase().includes(search.toLowerCase()) ||
          // comment.tipo.toLowerCase().includes(search.toLowerCase()) ||
          comment.importe_actual.toString().includes(search)
          );
    }
    if (selectedActivo) {
      computedComments = computedComments.filter((e) =>
        e.is_active.includes(selectedActivo)
      );
    }

    if (selectedFrecuencia) {
      computedComments = computedComments.filter((e) =>
        e.frecuencia.includes(frecuencia)
      );
    }
    setTotalItems(computedComments.length);

    //Sorting comments
if (
  sorting.field &&
  sorting.field != "importe_actual"
) {
  const reversed = sorting.order === "asc" ? 1 : -1;
  computedComments = computedComments.sort(
    (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
  );
}

if (
  sorting.field &&
  sorting.order === "asc" &&
  (sorting.field == "importe_actual")
) {
  computedComments = computedComments.sort((a, b) =>
    parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
  );
}

if (
  sorting.field &&
  sorting.order === "desc" &&
  (sorting.field == "importe_actual")
) {
  computedComments = computedComments.sort((a, b) =>
    parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
  );
}

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedActivo,
    selectedFrecuencia
  ]);

  function saveContrato() {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("contrato", idContrato);

    axios.post(URL_UPMEDIA, formData, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })

    .then((response) => {

      axios.patch(
        `${URL_RENTAS}/${idContrato}`,
        {
          archivo: "Si",
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )


      .then((response) => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Contrato",
            detalle: `Subir Archivo: ${file.name}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )

        .then((response) => {


        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
    

    setFile();
    setPhoto([]);
  }

  function borrarFoto(id) {

    axios.post(`${URL_DELMEDIA}/${id}`, {}, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })

    .then(() => {

      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);

    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
    

    // setFile();
    // setPhoto([]);
  }
  function jalaFoto(idCont) {
    const URL_GET_MEDIA = `${process.env.REACT_APP_URL_FINDMEDIABYTAG}/${idCont}`;
    axios
      .get(URL_GET_MEDIA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((data) => {
        setPhoto(data.data.resources);
        console.log("hola2", data.data.resources)
      })
      .catch((err) => console.log(err));
    setIdContrato(idCont);


    toggleFoto();
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.usuarios_permisos ? (
        <div className="card container col-sm-11">
          <Row>
            <Col sm={8}>
              {user.usuarios_permisos ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/RentasCreate"
                >
                  Nueva Renta
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nueva Renta
                </Button>
              )}
              {/* {user.usuarios_permisos ? (
                <Button
                  size="sm"
                  onClick={toggleGenera}
                  className="btn btn-success"
                  id="botonListado"
                >
                  Generar Mensaulidades
                </Button>
              ) : (
                <Button
                  size="sm"
                  disabled
                  className="btn btn-success"
                  id="botonListado"
                >
                  Generar Mensaulidades
                </Button>
              )} */}


              <Button
                size="sm"
                href="/MenuAdmin"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          
          <h3 align="center">Rentas</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label for="exampleEmail" className="mr-sm-12">
                Frecuencia
              </Label>
              <Input
                bsSize="sm"
                type="select"
                placeholder="Frecuencia"
                value={selectedFrecuencia}
                required
                onChange={(e) => {
                  setSelectedFrecuencia(e.target.value);
                }}
              >
                <option value="0">Selecciona</option>
                <option value="Mensual">Mensual</option>
                <option value="Anual">Anual</option>
              </Input>
            </Col>
            

            
            <Col md={2}>
              <Label for="exampleEmail" className="mr-sm-12">
                Activo
              </Label>
              <Input
                bsSize="sm"
                type="select"
                placeholder="Activo"
                value={selectedActivo}
                required
                onChange={(e) => {
                  setSelectedActivo(e.target.value);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </Input>
            </Col>
          </Row>
          <Table size="sm" striped borderless className="table-responsive-xl">
          <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData
                .map((c) => {
                  tablaImporte = tablaImporte + c.importe_actual
                    return (
                      <tr>
                        <td>{c.propiedad}</td>
                        <td>{c.cliente}</td>
                        <td>{c.fecha_inicio}</td>
                        <td>{c.fecha_fin}</td>
                        <td>{c.ultimo_aumento}</td>
                        <td>{c.frecuencia}</td>
                        <td align="center">
                          {new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 2,
                          }).format(c.importe_actual) +
                            " " +
                            c.moneda}
                        </td>
                        {/* <td>{c.tipo}</td> */}
                        <td>
                          {user.usuarios_permisos ? (
                            <div>
                              <Button
                                color="info"
                                id="Editar"
                                size="sm"
                                onClick={(e) =>
                                  jalaInfoEdit(
                                    c._id,
                                    c.idCliente,
                                    c.cliente,
                                    c.fecha_inicio,
                                    c.fecha_fin,
                                    c.descripcion,
                                    c.observaciones
                                  )
                                }
                              >
                                <i class="far fa-edit"></i>
                              </Button>
                              <Button
                                color="info"
                                id="Editar"
                                size="sm"
                                onClick={(e) => jalaFoto(c._id)}
                              >
                                <i class="fas fa-camera"></i>
                              </Button>
                              <Button
                                color="danger"
                                size="sm"
                                onClick={(e) => {
                                  setIdContrato(c._id);
                                  toggleContrato();
                                }}
                              >
                                <i class="fas fa-file"></i>
                              </Button>
                              <Button
                                color="primary"
                                id="Aumento"
                                size="sm"
                                onClick={(e) =>
                                  jalaInfoAumento(
                                    c._id,
                                    c.cliente,
                                    c.fecha_inicio,
                                    c.importe_anterior,
                                    c.importe_actual,
                                    c.importeKash,
                                    c.importeTar,
                                    c.ultimo_aumento,
                                    c.frecuencia,
                                    c.proximoCargo
                                  )
                                }
                              >
                                <i class="fas fa-dollar-sign"></i>
                              </Button>
                              <Baja
                                idStatus={c._id}
                                is_active={c.is_active}
                                URL_BAJA={process.env.REACT_APP_URL_RENTAS}
                              />
                            </div>
                          ) : (
                            <div>
                              <Button
                                color="info"
                                id="Editar"
                                size="sm"
                                disabled
                              >
                                <i class="far fa-edit"></i>
                              </Button>
                              <Button
                                color="primary"
                                id="Aumento"
                                size="sm"
                                disabled
                              ></Button>
                            </div>
                          )}
                        </td>
                      </tr>
                    );
                })}
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td className="negrita" align="center">
                  TOTAL
                </td>
                <td className="negrita" align="center">
                  {"$" + new Intl.NumberFormat("en-US").format(tablaImporte)}
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
            </div>
          </div>
        </div>
      ) : undefined}



      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4> Editar Cargo de {selectedClienteName}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6}>
              <Label className="mr-sm-2">Cliente</Label>
              <Input
                className="col-sm-12"
                type="select"
                value={selectedCliente}
                required
                onChange={(e) => {
                  setSelectedCliente(e.target.value);
                }}
              >
                <option value="0">Selecciona un Cliente</option>
                {clientes.map((c) => {
                  return (
                    <option value={c._id}>
                      {c.nombre_comercial}
                    </option>
                  );
                })}
              </Input>
            </Col>
            <Col md={6}>
            <Label className="mr-sm-2">Descripcion</Label>
          <Input
            className="col-sm-12"
            type="text"
            placeholder="Descripcion"
            value={descripcionEdit}
            required
            onChange={(e) => {
              setDescripcionEdit(e.target.value);
            }}
          />
             
            </Col>
          </Row>

          <Row>
            <Col md={6}>
            <Label>Fecha Inicio</Label>
              <Input
                type="date"
                value={fecha_inicio}
                required
                onChange={(e) => {
                  setFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={6}>
            <Label>Fecha Fin</Label>
              <Input
                type="date"
                value={fecha_fin}
                required
                onChange={(e) => {
                  setFechaFin(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
          <Col md={6}>
            <Label className="mr-sm-2">Observaciones</Label>
          <Input
            className="col-sm-12"
            type="text"
            placeholder="Observaciones"
            value={observacionesEdit}
            required
            onChange={(e) => {
              setObservacionesEdit(e.target.value);
            }}
          />
             
            </Col>
          </Row>
          
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editCargoMensual}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalAumento} toggle={toggleAumento}>
        <ModalHeader toggle={toggleAumento}>
          <h4>Editar Cargo de {selectedClienteName} </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Label>
              Importe Anterior{" "}
              {"$" + new Intl.NumberFormat("en-US").format(importe_anterior)}{" "}
            </Label>
          </Row>
          <Row>
            <Label>Ultimo Aumento {ultimo_aumento}</Label>
          </Row>
          <Row>
            <Label className="rojoBold">
              Importe Actual{" "}
              {"$" + new Intl.NumberFormat("en-US").format(importe_actual)}{" "}
            </Label>
          </Row>

          <Row>
          <Col md={6}>
                <Label>Importe K</Label>
                <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={importeKash}
                className={`form-control`}
                onValueChange={(value) => {
                  setImporteKash(value);
                  setNuevoImporte(parseFloat(value) + parseFloat(importeTar));
                }}
              />
              </Col>
            <Col md={6}>
                <Label>Importe T</Label>
                <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={importeTar}
                className={`form-control`}
                onValueChange={(value) => {
                  setImporteTar(value);
                  setNuevoImporte(parseFloat(value) + parseFloat(importeKash));
                }}
              />
              </Col>
                
          </Row>

          <Row>
            <Col md={6}>
            <Label>Nuevo Importe</Label>
            <CurrencyInput
                intlConfig={{ locale: "en-US", currency: "USD" }}
                prefix="$"
                placeholder="Ingresa una cantidad"
                defaultValue={0}
                decimalsLimit={2}
                value={nuevo_importe}
                className={`form-control`}
                disabled
              />

            </Col>

            <Col md={6}>
            <Label>Fecha de Aumento</Label>
            <Input
              type="date"
              value={fecha_aumento}
              required
              onChange={(e) => {
                setFechaAumento(e.target.value);
              }}
            />
            </Col>
            </Row>
            <Row>
              <Col md={6}>
              <Label>Proximo Cargo</Label>
            <Input
              type="date"
              value={proximoCargo}
              required
              onChange={(e) => {
                setProximoCargo(e.target.value);
              }}
            />
              </Col>
              <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-12">
              Frecuencia
            </Label>
            <Input
              type="select"
              placeholder="Frecuencia"
              value={frecuenciaEdit}
              required
              onChange={(e) => {
                setFrecuencia(e.target.value);
              }}
            >
              <option value="0">Frecuencia</option>
              <option value="Quincenal">Quincenal</option>
              <option value="Mensual">Mensual</option>
              <option value="Bimestral">Bimestral</option>
              <option value="Trimestral">Trimestral</option>
              <option value="Semestral">Semestral</option>
              <option value="Anual">Anual</option>
            </Input>
              </Col>

          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={aumentoCargoMensual}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Resumen Rentas</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

          <Modal size="sm" isOpen={modalContrato} toggle={toggleContrato}>
            <ModalHeader toggle={toggleContrato}>
              <h4>Subir Archivos</h4>
            </ModalHeader>
            <ModalBody>
            <Col md={12}>
              <Label>
                Archivo
              </Label>
              <Input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Col>  
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={saveContrato}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

          <Modal size="xxl" isOpen={modalFoto} toggle={toggleFoto}>
      <ModalHeader toggle={toggleFoto}>
        <h4>Archivos</h4>
      </ModalHeader>
      <ModalBody>
        <Row>
          {photo.length > 0 ? (
            photo.map((item) => (
              <div key={item.id} style={{ textAlign: "center", marginBottom: "20px" }}>
                {item.format === "pdf" ? (
                  <embed
                    src={item.secure_url}
                    type="application/pdf"
                    width="500"
                    height="600"
                  />
                ) : (
                  <img
                    src={item.secure_url}
                    alt="Uploaded Image"
                    width="250"
                    height="auto"
                  />
                )}
                <div>
                  <Button
                    color="danger"
                    onClick={() => borrarFoto(item.asset_id)}
                    style={{ marginTop: "10px" }}
                  >
                    Eliminar
                  </Button>
                </div>
              </div>
            ))
          ) : (
            <h4>No se ha subido una Foto o PDF....</h4>
          )}
        </Row>
      </ModalBody>
    </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          Estamos generando los cargos, este proceso puede tardar varios
          minutos.
          <br />
          Por favor no cierre ni refresque su navegador.
          <br />
          <div className="divPadre2">
            <div className="divHijo2">
              <Spinner
                style={{ width: "3rem", height: "3rem", color: "#232B41" }}
              />
              <Spinner
                style={{ width: "3rem", height: "3rem", color: "#232B41" }}
              />
              <Spinner
                style={{ width: "3rem", height: "3rem", color: "#232B41" }}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
      {loader}
    </>
  );
}

export default ListadoRentas;
